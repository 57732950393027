import React from "react";
import styled from "styled-components";
import { Typography, Grid } from "@material-ui/core";
import ContentfulImage from "../ContentfulImage";
import AnchorLink from "src/common/components/AnchorLink";
import ContentWidthWrapper from "src/common/components/ContentWidthWrapper";
import { useIntl } from "gatsby-plugin-intl"

const PressStage = ({
  title,
  subtitle,
  image,
  anchorLink,
}: {
  title: string | undefined | null;
  subtitle: string | undefined | null;
  image: string | undefined | null;
  anchorLink?: string;
}) => {
  const intl = useIntl()
  return (
    <Wrapper>
      <ContentWidthWrapper padding>
        <Grid container alignItems="flex-end">
          <Grid item xs={12} md={6}>
            {image && <ContentfulImage url={image} />}
          </Grid>
          <TextWrapper item xs={12} md={6}>
            <Headline variant="h1">{title}</Headline>
            <ContentWrapper>
              <Text variant="subtitle1">{subtitle}</Text>
              <Links>
                <AnchorLink to={`${anchorLink}/#germany`}>
                  {intl.formatMessage({ id: 'Germany' })}
                </AnchorLink>
                <AnchorLink to={`${anchorLink}/#austria`}>
                  {intl.formatMessage({ id: 'Austria' })}
                </AnchorLink>
                <AnchorLink to={`${anchorLink}/#swiss`}>{intl.formatMessage({ id: 'Switzerland' })}</AnchorLink>
                <AnchorLink to={`${anchorLink}/#central`}>{intl.formatMessage({ id: 'Central' })}</AnchorLink>
              </Links>
            </ContentWrapper>
          </TextWrapper>
        </Grid>
      </ContentWidthWrapper>
    </Wrapper>
  );
};

const Wrapper = styled("div")(
  ({ theme }) => `
    background-color: ${theme.palette.dust.main};
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    margin-bottom: ${theme.spacing(2)}px;
    ${theme.breakpoints.up("sm")} {
        flex-direction: row;
        padding: ${theme.spacing(15, 0, 0)};
        margin-bottom: ${theme.spacing(4)}px;
    }
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      display: block;
    }
`
);

const Text = styled(Typography)(
  ({ theme }) => `
  ${theme.breakpoints.up("sm")} {
    width: 66%;
}

`
);

const Links = styled("div")(
  ({ theme }) => `

    display: flex;
    flex-direction: column;

    margin-top: ${theme.spacing(1.5)}px;
    ${theme.breakpoints.up("sm")} {
        padding-left: ${theme.spacing(3)}px;
        margin-top: ${theme.spacing(0)}px;
        width: 33%;
    }
`
);

const TextWrapper = styled(Grid)(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-top: ${theme.spacing(3)}px;
    ${theme.breakpoints.up("md")} {
        margin-top: ${theme.spacing(0)};
        padding: ${theme.spacing(0, 6)};
    }
`
);

const ContentWrapper = styled("div")(
  ({ theme }) => `
    display: flex;
    flex-direction: column;
    ${theme.breakpoints.up("sm")} {
        flex-direction: row;
    }
`
);
const Headline = styled(Typography)(
  ({ theme }) => `
  margin-bottom: ${theme.spacing(2)}px;
  ${theme.breakpoints.up("sm")} {
    margin-bottom: ${theme.spacing(3)}px;
  }
`
);

export default PressStage;
