import React, { ReactNode } from "react";

import { Typography, Link } from "@material-ui/core";
import styled from "styled-components";
import theme from "src/constants/theme";

interface Props {
  text?: string;
  icon?: ReactNode;
  href: string;
}

const ContactLink = ({ text, icon, href }: Props) => {
  return (
    <LinkStyled href={href}>
      {icon}
      <TypographyStyled variant="body1">{text}</TypographyStyled>
    </LinkStyled>
  );
};

export default ContactLink;

const LinkStyled = styled(Link)`
  min-width: 260px;
  max-width: 435px;
  display: flex;
  align-items: center;
  margin-bottom: ${theme.spacing(1)}px;
  background-color: ${theme.palette.primary.contrastText};
  padding: ${theme.spacing(1, 2)};
  &:last-of-type {
    margin-bottom: 0;
  }
`;
const TypographyStyled = styled(Typography)`
  margin-left: ${theme.spacing(2)}px;
`;
