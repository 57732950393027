import React from "react";
import { graphql } from "gatsby";

import Layout from "src/common/Layout";
import SEO from "src/common/components/seo";

import Pager, { PaginationPageContext } from "../common/components/Pager";
import BlogPost from "src/common/components/BlogPost";
import { PressNewsPageQuery } from "types/graphql-types";
import PressContacts from "src/common/components/PressContacts/PressContacts";
import PressStage from "src/common/components/Stages/PressStage";
import styled from "styled-components";
import Grid from "@material-ui/core/Grid";
import DownloadLink from "src/common/components/DownloadLink";
import ContentWidthWrapper from "src/common/components/ContentWidthWrapper";

const IndexPage = ({
  data: { contentfulPressNewsPage, allContentfulNews, contentfulFooter, contentfulHeader },
  pageContext,
}:
  {
    data: PressNewsPageQuery;
    pageContext: PaginationPageContext;
  }
) => {
  if (!contentfulPressNewsPage || !allContentfulNews || !contentfulFooter || !contentfulHeader) {
    throw new Error(
      "Not all Contentful requirements met: contentfulPressNewsPage, allContentfulNews, contentfulHeader, contentfulFooter"
    );
  }
  const { pageConfig, downloads } = contentfulPressNewsPage;
  const { nodes } = allContentfulNews;

  return (
    <Layout
      contentfulHeader={contentfulHeader}
      contentfulFooter={contentfulFooter}
      navigationIsDark
      stageContent={
        <PressStage
          title={pageConfig!.title!.title!}
          subtitle={pageConfig!.subtitle!.subtitle!}
          image={pageConfig!.headerImage!.file!.url!}
          anchorLink={
            pageContext.humanPageNumber === 1
              ? "/news/"
              : `/news/${pageContext.humanPageNumber}`
          }
        />
      }
    >
      <SEO
        title={pageConfig!.seoConfig!.title!}
        description={pageConfig!.seoConfig!.description!}
        image={pageConfig!.seoConfig?.image?.localFile?.publicURL!}
      />
      <Container>
        <ContentWidthWrapper padding>
          <Grid container justify="space-between">
            <Downloads item xs={12} md={5}>
              {downloads?.map((download: any) => (
                <DownloadLink
                  href={download?.localFile?.publicURL!}
                  fileName={download?.title!}
                  backgroundColor
                  key={download.title}
                />
              ))}
            </Downloads>

            <Wrapper item xs={12} md={7}>
              <ContentWrapper>
                {nodes.map((news: any) => (
                  <BlogPost
                    date={news.creationDate!}
                    title={news.title!}
                    description={news.description!}
                    link={news.slug!}
                    key={news.id!}
                  />
                ))}
              </ContentWrapper>
              <Pager pageContext={pageContext} />
            </Wrapper>
          </Grid>
        </ContentWidthWrapper>
        <PressContacts
          contentfulPressNewsPage={contentfulPressNewsPage}
        />
      </Container>
    </Layout>
  );
};

export default IndexPage;

const Wrapper = styled(Grid)(
  ({ theme }) => `
  padding: ${theme.spacing(3, 0, 0)};
  ${theme.breakpoints.up("sm")} {
    padding: ${theme.spacing(3, 0, 0)};
  }
  ${theme.breakpoints.up("md")}{
    padding-right: ${theme.spacing(7)}px;
  }
  @media (min-width: 1280px) {
    padding-right: 0;
  }
`
);

const Container = styled("div")(
  ({ theme }) => `


`
);

const ContentWrapper = styled(Grid)(
  ({ theme }) => `
  border-bottom: 1px solid ${theme.palette.divider};
  margin: ${theme.spacing(1, 0, 0)}
`
);

const Downloads = styled(Grid)(
  ({ theme }) => `
  margin: ${theme.spacing(1, 0, 3, 0)};
  padding: ${theme.spacing(3, 3, 0, 0)};
  ${theme.breakpoints.up("sm")} {
    padding: ${theme.spacing(3, 7, 0, 0)};
  }
`
);

export const query = graphql`
query PressNewsPage($skip: Int!, $limit: Int!, $locale:String!) {
  contentfulPressNewsPage (node_locale: {eq: $locale}) {
    pressContactTitle
    pressContactCentral {
      title
      subtitleFirstLine
      subtitleSecondLine
      telephoneLabel
      telephoneNumber
      mailAdress
    }
    pressContactGermany {
      title
      subtitleFirstLine
      subtitleSecondLine
      telephoneLabel
      telephoneNumber
      mailAdress
    }
    pressContactAustria {
      title
      subtitleFirstLine
      subtitleSecondLine
      telephoneLabel
      telephoneNumber
      mailAdress
    }
    pressContactSwiss {
      title
      subtitleFirstLine
      subtitleSecondLine
      telephoneLabel
      telephoneNumber
      mailAdress
    }
      pageConfig {
        title {
          title
        }
        subtitle {
          subtitle
        }
        headerImage {
          file {
            url
          }
        }
        seoConfig {
          title
          description
          image {
            localFile {
              publicURL
            }
            file {
              url
            }
          }
        }
      }

      downloads {
        title
        file {
          url
          fileName
          contentType
        }
        localFile {
          publicURL
        }
      }
    }

    allContentfulNews(
      skip: $skip
      limit: $limit
      sort: { fields: creationDate, order: DESC }
      filter:{node_locale:{eq:$locale}}
    ) {
      nodes {
        id
        slug
        title
        description
        creationDate
        image {
          file {
            url
          }
        }
      }
    }
    contentfulFooter (node_locale: {eq: $locale}){
      title
      text
      copyright
      columnOneTitle
      columnOneLinks {
        id
        label
        url
        type
      }
      columnTwoTitle
      columnTwoLinks {
        id
        label
        url
        type
      }
      columnThreeTitle
      columnThreeLinks {
        id
        label
        url
        type
      }
      instagramLink
      facebookLink
      pinterestLink
      columnFourTitle
      childContentfulFooterLanguagesListJsonNode {
        de
        en
      }
      chosenLang
      deGermanFlag {
        file {
          url
        }
      }
      enEnglishFlag {
        file {
          url
        }
      }
      chosenFlag {
        file {
          url
          fileName
        }
      }
    }
    contentfulHeader (node_locale: {eq: $locale}){
      links {
        id
        label
        url
        type
      }
    }
  }
`;
