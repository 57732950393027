import React from "react";
import { GetApp as GetAppIcon } from "@material-ui/icons";

import { Typography, Link, Theme } from "@material-ui/core";
import styled from "styled-components";

interface Props {
  fileName: string;
  backgroundColor?: boolean;
  href: string;
}

const DownloadLink = ({ fileName, href, backgroundColor }: Props) => {
  let link = <span></span>;

  if (fileName) {
    link = <Link href={href} target="_blank" rel="noopener noreferrer">
      <StyledLink backgroundColor={backgroundColor ? backgroundColor : false}>
        <Typography variant="body1">{fileName}</Typography>
        <GetAppIcon height={14} />
      </StyledLink>
    </Link>

  }

  return (
    <>
      {link}
    </>
  );
};

export default DownloadLink;
interface StyleProps {
  backgroundColor: boolean;
  theme: Theme;
}
const StyledLink = styled.div<StyleProps>(
  ({ backgroundColor, theme }) => `
  min-width: 280px;
  max-width: 335px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: none;
  margin-bottom: ${theme.spacing(1)}px;
  padding: ${theme.spacing(1, 2)};
  background-color: ${backgroundColor
      ? `${theme.palette.dust.main}`
      : `${theme.palette.primary.contrastText}`
    };
`
);
