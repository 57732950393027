import React from "react";

import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { theme } from "src/constants/theme";

interface Card {
  company?: string;
  name?: string;
}

const ContactCard = ({ company, name }: Card) => {
  return (
    <CardStyled>
      <Typography variant="h6" gutterBottom>
        {company}
      </Typography>
      <Typography variant="body1">{name}</Typography>
    </CardStyled>
  );
};

export default ContactCard;

const CardStyled = styled.div`
  min-width: 260px;
  max-width: 435px;
  margin-bottom: ${theme.spacing(1)}px;
  background-color: ${theme.palette.primary.contrastText};
  padding: ${theme.spacing(3, 2, 2, 2)};
`;
