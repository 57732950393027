import React from "react";
import { Typography } from "@material-ui/core";
import { Link } from "gatsby";
import styled from "styled-components";
import formatDate from "src/util/formatDate";
import { useIntl } from "gatsby-plugin-intl"

// eslint-disable-next-line react-hooks/rules-of-hooks
const BlogPost = ({
  date,
  title,
  description,
  link,
}: {
  date: any;
  title: string;
  description: string;
  link: string;
}) => {
  const intl = useIntl()
  return (
    <Root>
      <TextWrapper>
        <Date variant="body2">{formatDate(date)}</Date>
        <Headline variant="h3">{title}</Headline>
        <Typography variant="subtitle1">{description}</Typography>
        <PostLink to={`/news/${link}`}>{intl.formatMessage({ id: 'readMore' })}</PostLink>
      </TextWrapper>
    </Root>
  );
};

export default BlogPost;

const Headline = styled(Typography)(
  (props) => `
  margin-bottom: ${props.theme.spacing(1)}px;
`
);

const Date = styled(Typography)(
  (props) => `
  margin-block-end: 0;
  margin-block-start: 0;
  margin-bottom: ${props.theme.spacing(1)}px;
`
);

const PostLink = styled(Link)(
  (props) => `
  font-weight: 700;
  color: ${props.theme.palette.black.main};
  font-size: 16px;
  font-family: Montserrat;
  line-height: 32px;
  margin-top: ${props.theme.spacing(2)}px;
  & :active {
    color: ${props.theme.palette.black.main};
  }
  &:hover {
    color: ${props.theme.palette.black.main} !important; 
  }
`
);

const Root = styled("div")(
  (props) => `
  display: flex;
  flex-direction: column;
  margin-bottom: ${props.theme.spacing(4)}px;
  align-items: flex-start;
  width: 100%;
  ${props.theme.breakpoints.up("sm")} {
    flex-direction: row;
    align-items: center;
  }
`
);

const TextWrapper = styled("div")(
  (props) => `
  display: flex;
  flex-direction: column;
  width: 100%;
  ${props.theme.breakpoints.up("sm")} {
    justify-content: center;
  }
`
);
