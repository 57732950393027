import React from "react";

import { Typography, Grid } from "@material-ui/core";
import ContactCard from "src/common/components/ContactCard";
import ContactLink from "src/common/components/ContactLink";
import { Mail } from "@material-ui/icons";
import { Phone } from "@material-ui/icons";
import styled from "styled-components";
import theme from "src/constants/theme";
import ContentWidthWrapper from "../ContentWidthWrapper";

const PressContacts = ({ contentfulPressNewsPage }) => {

  const pressContacts = Array.of(
    { ...contentfulPressNewsPage?.pressContactGermany!, link: "germany" },
    { ...contentfulPressNewsPage?.pressContactAustria!, link: "austria" },
    { ...contentfulPressNewsPage?.pressContactSwiss!, link: "swiss" }
  );
  const central = contentfulPressNewsPage?.pressContactCentral!;

  return (
    <ContactsStyled>
      <ContentWidthWrapper>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={12} md={5}>
            <Typography variant="h2" gutterBottom>
              {contentfulPressNewsPage?.pressContactTitle}
            </Typography>
            <SubtitleStyled variant="h3" id="central">
              {central.title}
            </SubtitleStyled>
            <CardStyled>
              <ContactLink
                href={`tel:${central.telephoneNumber}`}
                text={central.telephoneLabel!}
                icon={<Phone style={{ fontSize: 16 }} />}
              />
              <ContactLink
                href={`mailto:${central.mailAdress}`}
                text={central.mailAdress!}
                icon={<Mail style={{ fontSize: 16 }} />}
              />
            </CardStyled>
          </Grid>

          <Grid item xs={12} md={5} container justify="flex-end">
            <Grid item xs={12} md={10}>
              {pressContacts.map((contact) => (
                <CardStyled key={contact?.title!}>
                  <SubheadlineStyled variant="h3" id={contact.link}>
                    {contact?.title}
                  </SubheadlineStyled>
                  <ContactCard
                    company={contact?.subtitleFirstLine!}
                    name={contact?.subtitleSecondLine!}
                  />
                  <ContactLink
                    href={`tel:${contact?.telephoneNumber}`}
                    text={contact?.telephoneLabel!}
                    icon={<Phone style={{ fontSize: 16 }} />}
                  />
                  <ContactLink
                    href={`mailto:${contact?.mailAdress}`}
                    text={contact?.mailAdress!}
                    icon={<Mail style={{ fontSize: 16 }} />}
                  />
                </CardStyled>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </ContentWidthWrapper>
    </ContactsStyled>
  );
};

const ContactsStyled = styled.div(
  (props) => `
  background-color: ${theme.palette.dust.main};
  padding: ${theme.spacing(3, 3)};
  margin-bottom: ${theme.spacing(11)}px;
 ${props.theme.breakpoints.up("md")} {
    padding: ${theme.spacing(7, 5, 3)};

  }
`
);

const SubheadlineStyled = styled(Typography)(
  (props) => `
  margin-bottom: ${theme.spacing(3)}px;
  ${props.theme.breakpoints.down("xs")} {
    font-size: 16px;
  }
`
);
const CardStyled = styled.div`
  margin-bottom: ${theme.spacing(5)}px;
  box-sizing: content-box;
`;
const SubtitleStyled = styled(Typography)(
  (props) => `
  margin-bottom: ${theme.spacing(4)}px;
  ${props.theme.breakpoints.down("xs")} {
    font-size: 16px;
  }
`
);

export default PressContacts;
