import React from "react";
import styled from "styled-components";
import { Link } from "gatsby"
const StyledLink = styled(Link)(
  (props) => `
  color: ${props.theme.palette.black.main};
  background-color: transparent;
  font-size: 16px;
  border: 1px solid ${props.theme.palette.black.main};
  width: fit-content;
  padding: ${props.theme.spacing(1.5, 3)};
  text-decoration: none;
  margin-right: ${props.theme.spacing(1)}px;
  & focus {
    color: ${props.theme.palette.black.main}
  }
`
);

const CurrentPage = styled("div")(
  (props) => `
  background-color: ${props.theme.palette.black.main};
  border: 1px solid ${props.theme.palette.black.main};
  color: #fff;
  font-size: 16px;
  margin-right: ${props.theme.spacing(1)}px;
  padding: ${props.theme.spacing(1.5, 3)};
  width: fit-content;
  ;
`
);

export interface PaginationPageContext {
  pageNumber: number;
  humanPageNumber: number;
  skip: number;
  limit: number;
  numberOfPages: number;
  previousPagePath?: string;
  nextPagePath?: string;
}

const Pager = ({ pageContext }: { pageContext: PaginationPageContext }) => {
  const {
    previousPagePath,
    nextPagePath,
    pageNumber,
    humanPageNumber,
  } = pageContext;
  return (
    <Wrapper>
      {previousPagePath && (
        <StyledLink to={previousPagePath}>{pageNumber}</StyledLink>
      )}
      <CurrentPage>{humanPageNumber}</CurrentPage>
      {nextPagePath && (
        <StyledLink to={nextPagePath}>{humanPageNumber + 1}</StyledLink>
      )}
    </Wrapper>
  );
};

export default Pager;

const Wrapper = styled("div")(
  ({ theme }) => `
  display: flex;
  padding: ${theme.spacing(2, 0, 3)};
  ${theme.breakpoints.up("md")} {
    padding: ${theme.spacing(4, 0, 10)};
  }

`
);
