import React from "react";
import styled from "styled-components";
import { Link } from "gatsby-plugin-intl";
import { Link as ExternLink } from "@material-ui/core";

const AnchorLink = ({
  children,
  to,
  buttonStyle,
  href,
}: {
  children: React.ReactNode;
  to?: string | undefined | null;
  buttonStyle?: boolean;
  href?: string | undefined | null;
}) => {
  return (
    <>
      {href && (
        <ExternLink href={href} target="_blank" rel="noopener noreferrer">
          <StyledLink buttonStyle={buttonStyle || false}>{children}</StyledLink>
        </ExternLink>
      )}
      {to && (
        <Link to={to}>
          <StyledLink buttonStyle={buttonStyle || false}>{children}</StyledLink>
        </Link>
      )}
    </>
  );
};

const StyledLink = styled.div<{ buttonStyle: boolean }>(
  ({ theme, buttonStyle }) => `
    background-color: ${buttonStyle ? `${theme.palette.primary.main}` : "transparent"
    };
    color: ${buttonStyle
      ? `${theme.palette.primary.contrastText}`
      : `${theme.palette.primary.main}`
    };
    font-family: Montserrat;
    font-weight: ${buttonStyle ? 500 : 700};
    font-size: 16px;
    line-height: 32px;
    margin-top: ${theme.spacing(1)}px;
    padding: ${buttonStyle ? `${theme.spacing(2, 5)}` : 0};
    text-decoration ${buttonStyle ? "none" : "underline"};
    display: inline-block;
    &:hover {
      color: ${buttonStyle
      ? `${theme.palette.primary.contrastText}`
      : `${theme.palette.primary.main}`
    } !important; 
    }
  `
);

export default AnchorLink;
